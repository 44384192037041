<template>
      <div>
      <template >
        <!-- Header -->
      
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <b-row  >
            <b-col xl="4">
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="الاسم"
                label-for="full-name"
              >
                <b-form-input
                 
                  v-model="userData.first_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                 
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <b-col xl="4">
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="اسم الاب "
                label-for="father_name"
              >
                <b-form-input
                 
                  v-model="userData.father_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                 
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            </b-col>
            <b-col xl="4">
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="اسم الام"
                label-for="mother_name"
              >
                <b-form-input
                 
                  v-model="userData.mother_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                 
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="الكنية"
              rules="required"
            >
              <b-form-group
                label="الكنية"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="userData.last_name"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="الايميل"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
            <!-- Company -->
            <validation-provider
              #default="validationContext"
              name="Contact"
              rules="required"
            >
              <b-form-group
                label="رقم الهاتف"
                label-for="contact"
              >
                <b-form-input
                  id="contact"
                  v-model="userData.phone"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
            <!-- Company -->
            <validation-provider
              #default="validationContext"
              name="Company"
              rules="required"
            >
              <b-form-group
              label="العنوان"
                label-for="company"
              >
                <b-form-input
                  id="company"
                  v-model="userData.address"
                  :state="getValidationState(validationContext)"
                  trim
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
            <!-- Country -->
            <validation-provider
              #default="validationContext"
              name="Country"
              rules="required"
            >
          
              <b-form-group
                label="المنصب الوظيفي"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.applied_position"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
             
                  label="name"
                    :reduce="(val) => val.id"
                  :clearable="false"
                  :options="positionOptions"
          
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  <b-col xl="4">
      
            <!-- Plan -->
            <validation-provider
              #default="validationContext"
              name="CV"
              rules="required"
            >
              <b-form-group
                label="CV"
                label-for="CV"
                :state="getValidationState(validationContext)"
              >
              <b-form-file
                  id="cv"
                  accept=".pdf"
                  v-model="userData.cv"
                  :state="getValidationState(validationContext)"
                  
                />
                <a target="_blank" :href="`https://api.bacc-sy.org/${userData.cv}`">CV</a>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  </b-col>
  </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                تحديث
              </b-button>
             
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
    BRow,
    BCol,
    BFormFile
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { reactive, onUnmounted,ref } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import CandidatesStoreModule from '../CandidatesStoreModule'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useUsersList from './useUsersList'
  import Vue from "vue";
  import { useRouter } from "@core/utils/utils";
  
  import store from '@/store'

  
  export default {
    components: {
      BSidebar,
      BRow,
    BCol,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BFormFile,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },

    props: {
    
     
    },
    data() {
      return {
        required,
        alphaNum,
        email,
  
      }
    },
    setup(props, { emit }) {
      const CANDIDATES_APP_STORE_MODULE_NAME = 'app-candidates'

// Register module
if (!store.hasModule(CANDIDATES_APP_STORE_MODULE_NAME)) store.registerModule(CANDIDATES_APP_STORE_MODULE_NAME, CandidatesStoreModule)

// UnRegister on leave
onUnmounted(() => {
  if (store.hasModule(CANDIDATES_APP_STORE_MODULE_NAME)) store.unregisterModule(CANDIDATES_APP_STORE_MODULE_NAME)
})
      const { route } = useRouter();
    const id = route.value.params.id;
    store.dispatch("app-candidates/Getcandidates", { id }).then((response) => {
     //console.log("/api/v1/candidates",parseInt(response.data.data.applied_position)
// )
    
     blankUserData.value=response.data.data
     blankUserData.value.applied_position=parseInt(response.data.data.applied_position)

    
    });
      const blankUserData =ref( {
        first_name: '',
        father_name: '',
        mother_name: '',
        last_name: '',
        email: '',
        applied_position: null,
        cv: null,
       
        address: '',
        phone: '',
      })
  
      const userData = reactive(blankUserData)
      const resetuserData = () => {
        userData.value = JSON.parse(JSON.stringify(blankUserData))
      }
  
      const onSubmit = () => {
        const formData = new FormData();
        formData.append("last_name", userData.value.last_name);
        formData.append("mother_name", userData.value.mother_name);
        formData.append("father_name", userData.value.father_name);
        formData.append("phone", userData.value.phone);
        formData.append("address", userData.value.address);
        formData.append("applied_position", userData.value.applied_position);
        formData.append("email", userData.value.email);
        formData.append("cv", userData.value.cv);
        formData.append("first_name", userData.value.first_name);
        
          
        formData.append("_method", "put");
        // console.log("formData",formData)
        store.dispatch('app-candidates/UpdateCandidate',{ id, formData })
          .then(() => {
            Vue.swal({
                title: "تم  التعديل بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
            
          })
      }
    
      const {
     
      positionOptions,
      // UI
     
    } = useUsersList()
      const {
        refFormObserver,
        getValidationState,
        resetForm,
  
      } = formValidation(resetuserData)
  
      return {
        positionOptions,
        userData,
        id,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  